.override-w-size {
  width: 100%;
}
.override-flex-row{
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.override-flex-row button{
  min-width: 10rem;
}

.override-flex {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 640px) {
  .override-w-size {
    width: 75%;
  }

}

@media (min-width: 768px) {
  .override-flex {
    align-items: center;
    flex-direction: row;
  }
  .override-flex-row{
    flex-direction: row;
  }
}
