.faq-anim {
  height: 0;
  transition: all 1s;
}

.expand {
  height: 9rem;
}

.hiddenX {
  opacity: 0;
  transform: TranslateY(25px);
  transition: all 1s;
}
/*
.transition-delay-x1 {
  transition-delay: 1s;
}

.transition-delay-x2 {
  transition-delay: 2s;
}

.transition-delay-x3 {
  transition-delay: 3s;
}

.transition-delay-x4 {
  transition-delay: 4s;
}
*/
.showX {
  opacity: 1;
  transform: TranslateY(0);
}

@media (prefers-reduced-motion) {
  .hiddenX {
    transition: none;
  }
}
